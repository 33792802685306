export const RecruitmentPlanType = {
  DOMESTIC: 'domestic',
  OVERSEAS: 'overseas',
  DOMAIN: 'domain',
};

export const RecruitmentType = {
  GRAD: 'grad',
  INTERN: 'intern',
};

export const JobRole = {
  SUPERX: 'SuperX',
  SOFTWARE_DEVELOPER: 'Software Developer', // 后续不会再使用
  DEVELOPER: 'Developer', // 数据开发/软件开发/DevOps
  QUALITY_ANALYST: 'Quality Analyst',
  BUSINESS_ANALYST: 'Business Analyst',
  EXPERIENCE_DESIGNER: 'Experience Designer',
  GENERAL_ACCOUNTING: 'General accounting',
  PRODUCT_MANAGER: 'Product Manager',
  ADVISORY: 'Advisory',
};

export const StageName = {
  APPLICATION_REVIEW: 'Application Review',
  ASSIGNMENT: 'Assignment',
  RECRUITER_SCREEN: 'Recruiter Screen',
  TECHNICAL_SCREEN: 'Technical Screen',
  OFFICE_INTERVIEW: 'Office Interview',
  OFFICE_INTERVIEW1: 'Office Interview 1',
  OFFICE_INTERVIEW2: 'Office Interview 2',
  OFFER: 'Offer',
};
